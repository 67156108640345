import axios from "axios"

/**
    * @function allParticipantService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all participant
*/
export const allParticipantService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/tournament/players?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleReceiptService
    * @param { receipt_no, tournamentId }
    * @description This function is used to get single receipt info
*/
export const singleReceiptService = async({ receipt_no, tournamentId }) =>{
    try {
        const result = await axios.get(`/admin/tournament/single-receipt/${receipt_no}/${tournamentId}`)
        return result;
    } catch (error) {
        return error;
    }
}