import { useState, useEffect } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import { Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector} from 'react-redux';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaTrashAlt, FaRegEdit } from "react-icons/fa";
import moment from 'moment'

// Custom Component
import Add from './Add'
import Edit from './Edit'
import { PrimaryButton } from '../../components/Button';
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import ConfirmAlert from '../../components/Alert/ConfirmAlert';

// API Service
import { allBannerService, bannerStatusService, deleteBannerService } from '../../services/banner.service';

const Banner = () => {
    const [bannerList, setBannerList] = useState([])
    const [selectedData, setSelectedData] = useState(null);
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const countPerPage = 10;

    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)

    /**
         * @function fetchBannerList
         * @params page
         * @description fetch the list of banner
         */
    const fetchBannerList = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allBannerService({
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: ""
            });
            if (result?.data?.status) {
                setBannerList(result?.data?.data?.banner_lists)
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    useEffect(() => {
        fetchBannerList(currentPage)
    }, [])

    /**
         * @function handleAddModal
         * @params
         * @description set the visibility of add modal
         */
    const handleAddModal = () => {
        setAddModal(!addModal)
    }

    /**
         * @function handleEditModal
         * @params row
         * @description set the visibility of edit modal
         */
    const handleEditModal = (row) => {
        setEditModal(!editModal)
        setSelectedData(row)
    }

    /**
         * @function statusUpdate
         * @params rowRecord
         * @description used to change status
         */
    const statusUpdate = async (rowRecord) => { 
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await bannerStatusService(rowRecord?.id);
            if (result?.data?.status) {
                SuccessAlert('Status updated successfully')
                fetchBannerList(currentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
         * @function deleteBanner
         * @params id
         * @description delete the banner
         */
    const deleteBanner = async (id) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await deleteBannerService(id);
            if (result?.data?.status) {
                SuccessAlert('Banner deleted successfully')
                fetchBannerList(currentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => currentPage > 0 ? (currentPage * 10) + index + 1 : index + 1
        },
        {
            name: 'Image',
            selector: (row) => row.image ? <img className='img-fluid' src={row.image} alt={row.title} width={32} /> : '--',
            sortable: true
        },
        {
            name: 'Type',
            selector: (row) => row.type ? row.type === 1 ? 'Customer' : row.type === 2 ? 'Organizer' : '--' : '--',
            sortable: true
        },
        {
            name: 'Title',
            selector: (row) => row.title ? row.title : '--',
            sortable: true
        },
        {
            name: 'URL',
            selector: (row) => row.url ? row.url : '--',
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => (
                <Form.Check
                    type="switch"
                    id="success-switch"
                    checked={row.is_enabled}
                    onChange={() => statusUpdate(row)}
                    defaultChecked={row.is_enabled}
                />
            )
        },
        {
            name: 'Created Date',
            selector: (row) => moment(row.created_at).format("DD-MM-YYYY")
        },
        {
            name: 'Actions',
            allowOverflow: true,
            selector: (row) => <>
                <FaTrashAlt role='button' className='text-danger me-2' size={20} onClick={() => ConfirmAlert(() => deleteBanner(row?.id))} />
                <FaRegEdit role='button' className='text-orange' size={20} onClick={() => handleEditModal(row)} />
            </>
        }
    ]

    /**
         * @function handlePagination
         * @params page
         * @description used to handle Pagination
         */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchBannerList(page.selected)
    }

    // ** Custom Pagination
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <CCard className="mb-4">
                        <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                            <h5>Banner Management</h5>
                            <div>
                                <PrimaryButton onClick={handleAddModal}>Add Banner</PrimaryButton>
                            </div>
                        </CCardHeader>
                    </CCard>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            data={bannerList}
                            pagination
                            paginationServer
                            paginationTotalRows={totalEntry}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            paginationComponent={CustomPagination}
                            paginationDefaultPage={currentPage + 1}
                        />
                    </div>
                </CCol>
            </CRow>
        </CContainer>

        {/* Add Modal */}
        <Add show={addModal} handleClose={handleAddModal} pageCount={currentPage} fetchBannerList={fetchBannerList} />

        {/* Edit Modal */}
        <Edit show={editModal} handleClose={handleEditModal} selectedData={selectedData} pageCount={currentPage} fetchBannerList={fetchBannerList} />
    </div>
}

export default Banner
