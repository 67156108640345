import axios from "axios"

/**
    * @function allUserService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all user
*/
export const allUserService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/customer/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function userStatusService
    * @param { id }
    * @description This function is used to change user status
*/
export const userStatusService = async({ id }) =>{
    try {
        const result = await axios.post(`/admin/customer/status/${id}`, {})
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getSingleUserService
    * @param { id }
    * @description This function is used to get single user details
*/
export const getSingleUserService = async({ id }) =>{
    try {
        const result = await axios.get(`/admin/customer/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateUserService
    * @param { id, data }
    * @description This function is used to update user
*/
export const updateUserService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/admin/customer/edit/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteUserService
    * @param { id }
    * @description This function is used to delete user
*/
export const deleteUserService = async({ id }) =>{
    try {
        const result = await axios.delete(`/admin/customer/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}