import { useState, useEffect } from 'react'
import { Row, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { CSVLink } from "react-csv";
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import classNames from "classnames";

// Custom Component
import NoPlayer from "./NoPlayer";
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Utils
import { convertEventType } from '../../utils/helper';

// API Service
import { allPlayerService } from '../../services/tournament.service';

const MyPlayer = (props) => {
    let { tournamentId } = useParams();

    const [playerList, setPlayerList] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [selectedGender, setSelectedGender] = useState('');
    const [csvData, setCSVData] = useState([]);

    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);

    const csvHeaders = [
        { label: 'User name', key: 'user_name' },
        { label: 'Player Name', key: 'player_name' },
        { label: 'Club Name', key: 'club_name' },
        { label: 'Team Id', key: 'team_id' },
        { label: 'Email address', key: 'player_email' },
        { label: 'Phone number', key: 'player_contact' },
        { label: 'Events', key: 'event_type' },
        { label: 'Gender', key: 'player_gender' },
        { label: 'Player Age', key: 'player_dob' },
        { label: 'IC/Passport number', key: 'player_idcard' },
        { label: 'Country', key: 'nationality' },
        { label: 'User Phone no', key: 'phone' },
        { label: 'Status', key: 'player_status' }
    ];

    const { register, watch } = useForm({
        mode: "onBlur",
        defaultValues: {
            event_type: '',
            event_type_lg: ''
        }
    })

    const watchEventType = watch("event_type")
    const watchEventTypeLg = watch("event_type_lg")

    /**
         * @function handleGender
         * @params gender
         * @description set the value of gender
         */
    const handleGender = (gender) => {
        setSelectedGender(gender)
    }

    /**
        * @function fetchPlayerList
        * @params page
        * @description fetch the list of player
    */
    const fetchPlayerList = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allPlayerService({
                id: tournamentId,
                gender: selectedGender,
                event_type: watchEventType || watchEventTypeLg,
                paginate: 1,
                page,
                perPage: 10,
                keyword: ''
            });
            if (result?.data?.status) {
                setPlayerList(result?.data?.data?.player_lists)
                setTotalEntry(result?.data?.data?.pagination?.total_pages || 1)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
        * @function fetchAllPlayerList
        * @params
        * @description fetch the list of all player
    */
    const fetchAllPlayerList = async () => {
        try {
            const result = await allPlayerService({
                id: tournamentId,
                gender: "",
                event_type: "",
                paginate: 0,
                page: "",
                perPage: "",
                keyword: ""
            });
            if (result?.data?.status) {
                if (result?.data?.data?.player_lists?.length > 0) {
                    setCSVData(result?.data?.data?.player_lists?.map(item => {
                        return {
                            user_name: item?.customer?.username || '-',
                            player_name: item?.player_name || '-',
                            club_name: item?.club_name || '-',
                            team_id: item?.team_id || '-',
                            player_email: item?.player_email || '-',
                            player_contact: item?.player_contact || '-',
                            event_type: ((item?.event_type || item?.team_event_type) && `${(item?.team_event_type) ? 'Team - ' : 'Individual - '}${item?.team_event_type ? item?.team_event_type?.map(teamItem => convertEventType(teamItem))?.join(", ") : item?.event_type ? convertEventType(item?.event_type) : '-'}`) || '-',
                            player_gender: item?.player_gender || '-',
                            player_dob: (item?.player_dob && moment().diff(item?.player_dob, 'years')) || '-',
                            player_idcard: item?.player_idcard || '-',
                            nationality: item?.nationality || '-',
                            phone: item?.customer?.phone ? `=""${item?.customer?.phone}""` : '-',
                            player_status: item?.player_status ? (item?.player_status === "0" || item?.player_status === "ACTIVE") ? 'ACTIVE' : item?.player_status : '-'
                        }
                    }))
                } else {
                    setCSVData([])
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (tournamentId) {
            fetchPlayerList(currentPage + 1);
        }
    }, [tournamentId, currentPage])

    useEffect(() => {
        if (tournamentId) {
            fetchAllPlayerList();
        }
    }, [tournamentId])

    useEffect(() => {
        if (tournamentId) {
            if (currentPage === 0) {
                fetchPlayerList(currentPage + 1);
            } else {
                setCurrentPage(0)
            }
        }
    }, [tournamentId, selectedGender, watchEventType, watchEventTypeLg])

    const columns = [
        {
            name: 'Name',
            selector: (row) => row?.player_name ? <span className='fs-14 fw-600 text-capitalize'>{row?.player_name}</span> : '-',
            sortable: true
        },
        {
            name: 'Club Name',
            selector: (row) => row?.club_name ? <span className='fs-14 fw-600 text-capitalize'>{row?.club_name}</span> : '-',
            sortable: true
        },
        {
            name: 'Team Id',
            selector: (row) => row?.team_id ? <span className='fs-14 text-secondary'>{row?.team_id}</span> : '-',
            sortable: true
        },
        {
            name: 'Email address',
            selector: (row) => row?.player_email ? <span className='fs-14 text-secondary'>{row?.player_email}</span> : '-',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Phone number',
            selector: (row) => row?.player_contact ? <span className='fs-14 text-secondary'>{row?.player_contact}</span> : '-',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Events',
            selector: (row) => row?.event_type || row?.team_event_type ? <div className='d-flex flex-column align-items-start fs-14 fw-400 text-secondary'>
                {(row?.team_event_type) ?
                    <span>Team - </span>
                    :
                    <span>Individual - </span>
                }
                <span>
                    {row?.team_event_type ?
                        row?.team_event_type?.map(teamItem => convertEventType(teamItem))?.join(", ")
                        :
                        convertEventType(row?.event_type)
                    }
                </span>
            </div> : '-',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Gender',
            selector: (row) => row?.player_gender ? <span className='fs-14 text-secondary'>{row?.player_gender === 'MALE' ? "Male" : 'Female'}</span> : '-',
            sortable: true
        },
        {
            name: 'Player Age',
            selector: (row) => row?.player_dob ? <span className='fs-14 text-secondary'>{moment().diff(row?.player_dob, 'years')}</span> : '-',
            sortable: true
        },
        {
            name: 'IC/Passport number',
            selector: (row) => row?.player_idcard ? <span className='fs-14 text-secondary'>{row?.player_idcard}</span> : '-',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Nationality',
            selector: (row) => row?.nationality ? <span className='fs-14 text-secondary text-capitalize'>{row?.nationality}</span> : '-',
            sortable: true
        },
        {
            name: 'User name',
            selector: (row) => row?.customer?.username ? <span className='fs-14 fw-600 text-capitalize'>{row?.customer?.username}</span> : '-',
            sortable: true,
            width: '200px'
        },
        {
            name: 'Phone no',
            selector: (row) => row?.customer?.phone ? <span className='fs-14 text-capitalize'>{row?.customer?.phone}</span> : '-',
            sortable: true,
            width: '150px'
        },
        {
            name: 'Status',
            selector: (row) => row?.player_status ? <span className='fs-14 text-secondary text-capitalize'>{(row?.player_status === "0" || row?.player_status === "ACTIVE") ? 'ACTIVE' : row?.player_status}</span> : '-'
        }
    ]

    /**
         * @function handlePagination
         * @params page
         * @description used to handle Pagination
         */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }

    /**
         * @function CustomPagination
         * @params
         * @description used for Custom Pagination
         */
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='d-none d-lg-flex pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <Row className='my-3'>
        <div className='d-flex flex-column flex-lg-row align-items-start mb-3'>
            <div className='d-flex justify-content-between justify-content-lg-start align-items-center mb-3 w-100'>
                <div className='d-flex align-items-center flex-grow-1'>
                    <span className='fs-24 fw-600 me-2'>Players</span>
                    <span className='fs-12 fw-600 text-orange px-2 py-1 bg-danger bg-opacity-10 rounded me-5'>{csvData?.length || playerList?.length || 0} players</span>
                    <div className='d-none d-lg-flex align-items-center fs-14 text-secondary me-3'>
                        <span role='button' className={classNames('me-2', { "text-orange fw-600": selectedGender === '' })} onClick={() => handleGender('')}>All</span>
                        <span role='button' className={classNames('me-2', { "text-orange fw-600": selectedGender === 'MALE' })} onClick={() => handleGender('MALE')}>Male</span>
                        <span role='button' className={classNames({ "text-orange fw-600": selectedGender === 'FEMALE' })} onClick={() => handleGender('FEMALE')}>Female</span>
                    </div>
                    <Form.Group className='d-none d-lg-block flex-grow-1 me-4'>
                        <Form.Select
                            {...register("event_type_lg")}
                        >
                            <option value="">Events</option>
                            <option value="MEN_SINGLE">Men Single</option>
                            <option value="WOMEN_SINGLE">Women Single</option>
                            <option value="MEN_DOUBLE">Men Double</option>
                            <option value="WOMEN_DOUBLE">Women Double</option>
                            <option value="MIX_DOUBLE">Mix Double</option>
                            <option value="MEN_3_3">Men 3 on 3</option>
                            <option value="WOMEN_3_3">Women 3 on 3</option>
                            <option value="MIX_3_3">Mix 3 on 3</option>
                            <option value="JUNIOR_SINGLE">Junior Single</option>
                            <option value="JUNIOR_DOUBLE">Junior Double</option>
                        </Form.Select>
                    </Form.Group>
                </div>
                <CSVLink className="text-decoration-none" filename={`${props?.tournament?.name || "my"}-Players.csv`} data={csvData} headers={csvHeaders}>
                    <span role='button' className='fs-12 fw-600 text-orange px-2 py-1 bg-danger bg-opacity-10 rounded'>Export CSV</span>
                </CSVLink>
            </div>
            <div className='d-flex d-lg-none align-items-center fs-14 text-secondary'>
                <span role='button' className={classNames('me-2', { "text-orange fw-600": selectedGender === '' })} onClick={() => handleGender('')}>All</span>
                <span role='button' className={classNames('me-2', { "text-orange fw-600": selectedGender === 'MALE' })} onClick={() => handleGender('MALE')}>Male</span>
                <span role='button' className={classNames({ "text-orange fw-600": selectedGender === 'FEMALE' })} onClick={() => handleGender('FEMALE')}>Female</span>
            </div>
            <Form.Group className='d-lg-none flex-grow-1 mt-3 w-100'>
                <Form.Select
                    {...register("event_type")}
                >
                    <option value="">Events</option>
                    <option value="MEN_SINGLE">Men Single</option>
                    <option value="WOMEN_SINGLE">Women Single</option>
                    <option value="MEN_DOUBLE">Men Double</option>
                    <option value="WOMEN_DOUBLE">Women Double</option>
                    <option value="MIX_DOUBLE">Mix Double</option>
                    <option value="MEN_3_3">Men 3 on 3</option>
                    <option value="WOMEN_3_3">Women 3 on 3</option>
                    <option value="MIX_3_3">Mix 3 on 3</option>
                    <option value="JUNIOR_SINGLE">Junior Single</option>
                    <option value="JUNIOR_DOUBLE">Junior Double</option>
                </Form.Select>
            </Form.Group>
        </div>
        {playerList.length > 0 ?
            <>
                <DataTable
                    className='d-none d-lg-block'
                    columns={columns}
                    data={playerList}
                    pagination
                    paginationServer
                    paginationTotalRows={10}
                    paginationPerPage={10}
                    paginationComponentOptions={{ noRowsPerPage: true }}
                    paginationComponent={CustomPagination}
                    paginationDefaultPage={currentPage + 1}
                />
                <div>
                    {playerList?.map((item, index) => {
                        return <div className='d-flex d-lg-none flex-column align-items-start border rounded px-2 py-3 mb-3' key={index}>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <span className='fs-14 fw-600 text-capitalize text-truncate'>{item?.player_name}</span>
                            </div>
                            <div className='d-flex flex-column align-items-start w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary mb-1'>Team Id: {item?.team_id || "-"}</span>
                                <span className='fs-14 fw-400 text-secondary mb-1'>Club Name: {item?.club_name || "-"}</span>
                                {item?.player_status ? <span className='fs-14 text-secondary text-capitalize'>Status: {(item?.player_status === "0" || item?.player_status === "ACTIVE") ? 'ACTIVE' : item?.player_status}</span> : 'Status: -'}
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary me-4'>{item?.player_gender === 'MALE' ? "Male" : 'Female'}</span>
                                <span className='fs-14 fw-400 text-secondary'>Age {moment().diff(item?.player_dob, 'years')}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary me-4'>{item?.player_email}</span>
                                <span className='fs-14 fw-400 text-secondary'>{item?.player_contact}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary'>{`${(item?.team_event_type) ? 'Team - ' : 'Individual - '}${(item?.team_event_type) ? 'Team - ' : 'Individual - '}${item?.team_event_type ? item?.team_event_type?.map(teamItem => convertEventType(teamItem))?.join(", ") : item?.event_type ? convertEventType(item?.event_type) : '-'}`}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary'>IC/Passport number : {item?.player_idcard}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary text-capitalize'>{item?.nationality}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary text-capitalize'>User name: {item?.customer?.username}</span>
                            </div>
                            <div className='d-flex align-items-center w-100 mt-1'>
                                <span className='fs-14 fw-400 text-secondary text-capitalize'>Phone no: {item?.customer?.phone}</span>
                            </div>
                        </div>
                    })}
                    <ReactPaginate
                        nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <span className='fs-14 fw-600 me-2'>Next</span>
                            <AiOutlineArrowRight className='text-secondary' size={20} />
                        </div>}
                        breakLabel='...'
                        previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                            <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                            <span className='fs-14 fw-600'>Previous</span>
                        </div>}
                        pageRangeDisplayed={3}
                        forcePage={currentPage}
                        marginPagesDisplayed={3}
                        activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
                        pageClassName='page-item me-3 d-flex align-items-center text-secondary'
                        breakClassName='page-item me-3 d-flex align-items-center'
                        nextClassName='page-item next-item flex-grow-1 text-end'
                        previousClassName='page-item prev-item flex-grow-1 me-3'
                        disabledLinkClassName='btn disabled p-0 border-0'
                        pageCount={totalEntry || 1}
                        onPageChange={page => handlePagination(page)}
                        containerClassName='d-lg-none pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
                    />
                </div>
            </>
            :
            <NoPlayer />
        }
    </Row>
};

export default MyPlayer;