import { useState, useEffect } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { CSVLink } from "react-csv";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { PiUserCircleLight } from "react-icons/pi";
import moment from 'moment'

// Custom Component
import { PrimaryButton } from '../../components/Button';
import SearchInput from '../../components/SearchInput/SearchInput';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allSubOrganizerService } from '../../services/suborganizer.service';

const SubOrganizer = () => {
    let { organizerId } = useParams();

    const [subOrganizerList, setSubOrganizerList] = useState([])
    const [searchTag, setSearchTag] = useState("")
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [csvData, setCSVData] = useState([]);
    const countPerPage = 10;

    const csvHeaders = [
        { label: 'Id', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Email address', key: 'email' },
        { label: 'Phone number', key: 'phone' },
        { label: 'Gender', key: 'gender' },
        { label: 'Created Date', key: 'created_at' }
    ]

    /**
         * @function fetchListedSubOrganizer
         * @params page
         * @description fetch the list of suborganizer
         */
    const fetchListedSubOrganizer = async (page) => {
        try {
            const result = await allSubOrganizerService({
                organizerId,
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: searchTag
            });
            if (result?.data?.status) {
                setSubOrganizerList(result?.data?.data?.organizers)
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
         * @function fetchAllSubOrganizerList
         * @params
         * @description fetch the list of all suborganizer
         */
    const fetchAllSubOrganizerList = async () => {
        try {
            const result = await allSubOrganizerService({
                organizerId,
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                setCSVData(result?.data?.data?.organizers?.map((item, index) => {
                    return {
                        id: index + 1,
                        name: item?.name || '-',
                        email: item?.email || '-',
                        phone: item?.phone || '-',
                        gender: item?.gender === 'MALE' ? "Male" : 'Female' || '-',
                        created_at: moment(item?.created_at).format("DD-MM-YYYY")
                    }
                }))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchListedSubOrganizer(currentPage)
    }, [searchTag])

    useEffect(() => {
        fetchAllSubOrganizerList()
    }, [])

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => currentPage > 0 ? (currentPage * 10) + index + 1 : index + 1
        },
        {
            name: 'Image',
            selector: (row) => row?.profile_image ? <img className='rounded-circle' src={row?.profile_image} alt={row?.name} width={30} height={30} /> : <PiUserCircleLight className="text-secondary rounded-circle" size={32} />,
            sortable: true,
            maxWidth: "40px"
        },
        {
            name: 'Name',
            selector: (row) => row?.name ? <span className='fs-14 fw-600 text-capitalize'>{row?.name}</span> : '-',
            sortable: true
        },
        {
            name: 'Email address',
            selector: (row) => row?.email ? <span className='fs-14 text-secondary'>{row?.email}</span> : '-',
            sortable: true
        },
        {
            name: 'Phone number',
            selector: (row) => row?.phone ? <span className='fs-14 text-secondary'>{row?.phone}</span> : '-',
            sortable: true
        },
        {
            name: 'Gender',
            selector: (row) => row?.gender ? <span className='fs-14 text-secondary'>{row?.gender === 'MALE' ? "Male" : 'Female'}</span> : '-',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Status',
            selector: (row) => (
                <Form.Check
                    type="switch"
                    id="success-switch"
                    checked={row?.status}
                />
            ),
            width: '100px'
        },
        {
            name: 'Created Date',
            selector: (row) => moment(row?.created_at).format("DD-MM-YYYY")
        }
    ]

    /**
         * @function handlePagination
         * @params page
         * @description used to handle Pagination
         */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchListedSubOrganizer(page.selected)
    }

    /**
         * @function searchSubOrganizer
         * @params searchTerm
         * @description used to set the value of search tag
         */
    const searchSubOrganizer = (searchTerm) => {
        setCurrentPage(0)
        setSearchTag(searchTerm)
    }

    // ** Custom Pagination
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <CCard className="mb-4">
                        <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                            <h5>Sub Organizer Management</h5>
                            <div className='d-flex align-items-center'>
                                <div className='me-2'>
                                    {csvData?.length > 0 &&
                                        <CSVLink filename={`suborganizer.csv`} data={csvData} headers={csvHeaders}>
                                            <PrimaryButton>Export</PrimaryButton>
                                        </CSVLink>
                                    }
                                </div>
                                <Form id='search-form' noValidate>
                                    <SearchInput placeholder='Search Sub Organizer' search={searchSubOrganizer} />
                                </Form>
                            </div>
                        </CCardHeader>
                    </CCard>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            data={subOrganizerList}
                            pagination
                            paginationServer
                            paginationTotalRows={totalEntry}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            paginationComponent={CustomPagination}
                            paginationDefaultPage={currentPage + 1}
                        />
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    </div>
};

export default SubOrganizer;
