import axios from "axios"

/**
    * @function allMessageService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all message
*/
export const allMessageService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/contact-us/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getSingleMessageService
    * @param id
    * @description This function is used to get single message details
*/
export const getSingleMessageService = async(id) =>{
    try {
        const result = await axios.get(`/admin/contact-us/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteMessageService
    * @param id
    * @description This function is used to delete message
*/
export const deleteMessageService = async(id) =>{
    try {
        const result = await axios.delete(`/admin/contact-us/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}