import { useState } from 'react';
import { Link } from 'react-router-dom'
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import { Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { LuEye, LuEyeOff } from "react-icons/lu";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Images
import { logo } from '../../assets/images'
import { LoginSVG } from '../../assets/images/svg'

// API Service
import { loginService } from '../../services/auth.service';

// Utility Service
import { setUserSession } from '../../utils/AuthService';

const Login = () => {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    /**
         * @function handleShowPassword
         * @params
         * @description toggle the password input field visibilty
         */
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

     /**
         * @function onSubmit
         * @params data
         * @description used to signin the admin
         */
    const onSubmit = async (data) => {
        try {
            const result = await loginService({
                email: data.email.trim(),
                password: data.password.trim()
            });

            if (result?.status === 200) {
                setUserSession(result?.data?.token, result?.data?.user);
                navigate('/dashboard');
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <CContainer className='vh-100 d-flex flex-column'>
        <CRow>
            <CCol xs={6} lg={2} className='px-3 pt-3'>
                <Link to='/'>
                    <img className='img-fluid' src={logo} alt='AFA' />
                </Link>
            </CCol>
        </CRow>
        <CRow className='my-auto'>
            <CCol lg={8} className='d-none d-lg-flex align-items-center'>
                <LoginSVG />
            </CCol>
            <CCol xs={8} sm={6} md={5} lg={4} className='d-lg-flex justify-content-start align-items-center mx-auto mx-lg-0'>
                <CCardGroup className='w-100'>
                    <CCard className="border-0">
                        <CCardBody className='p-0'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <h2 className='fw-bold'>Welcome to <br /> AFA Tournament</h2>
                                <Form.Group className="mb-4 field-group">
                                    <Form.Label className='fs-14 fw-500'>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Email here"
                                        {...register("email", {
                                            required: "Email is required",
                                        })}
                                    />
                                    {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
                                </Form.Group>
                                
                                <Form.Group className="mb-4">
                                    <Form.Label className='fs-14 fw-500'>Password*</Form.Label>
                                    <InputGroup className='rounded'>
                                        <Form.Control
                                            className='border-end-0'
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            {...register('password', {
                                                required: "Password is required",
                                            })}
                                        />
                                        <InputGroup.Text role='button' className='bg-transparent border-start-0' onClick={handleShowPassword}>{showPassword ? <LuEyeOff /> : <LuEye />}</InputGroup.Text>
                                    </InputGroup>
                                    {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                                </Form.Group>
                                <CRow>
                                    <CCol xs={6}>
                                        <CButton color="primary" type="submit" className="px-4">
                                            Login
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </Form>
                        </CCardBody>
                    </CCard>
                </CCardGroup>
            </CCol>
        </CRow>
    </CContainer>
}

export default Login;
