import { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useForm } from "react-hook-form";

// Custom Component
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allCountryService } from '../../services/country.service';

const View = (props) => {
    const [countryList, setCountryList] = useState([])

    const { register, reset } = useForm({ mode: "onBlur" });

    /**
         * @function fetchCountryList
         * @params
         * @description fetch the list of country
         */
    const fetchCountryList = async () => {
        try {
            const result = await allCountryService({
                paginate: '',
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                setCountryList(result?.data?.data?.country_list)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, [])

    useEffect(() => {
        if (props?.selectedData && countryList?.length > 0) {
            let defaultValues = {};
            defaultValues.first_name = props?.selectedData?.first_name
            defaultValues.last_name = props?.selectedData?.last_name
            defaultValues.email = props?.selectedData?.email
            defaultValues.country = props?.selectedData?.country
            defaultValues.phone = props?.selectedData?.phone
            defaultValues.message = props?.selectedData?.message
            reset({ ...defaultValues })
        }
    }, [props?.selectedData, countryList])

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header className='fs-18 fw-600 border-0' closeButton>
            View Message
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-center'>
            <Form className='w-100'>
                <Row>
                    <Col xs={12} sm={6}>
                        <Form.Group>
                            <Form.Label className='fs-14 fw-500'>First name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="First name"
                                {...register("first_name", {
                                    required: "First name is required"
                                })}
                                disabled={true}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Group>
                            <Form.Label className='fs-14 fw-500'>Last name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Last name"
                                {...register("last_name", {
                                    required: "Last name is required"
                                })}
                                disabled={true}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-500'>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="you@team.com"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        })}
                        disabled={true}
                    />
                </Form.Group>
                                    
                {countryList.length > 0 &&
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Phone number*</Form.Label>
                        <InputGroup className='rounded'>
                            <Form.Select
                                {...register("country")}
                                disabled={true}
                            >
                                {countryList.map((item, index) => {
                                    return <option value={item?.id} key={index}>{item?.code}</option>
                                })}
                            </Form.Select>
                            <Form.Control
                                className='flex-grow-5'
                                type="tel"
                                placeholder="Enter Phone number"
                                {...register("phone", {
                                    required: "Phone is required"
                                })}
                                disabled={true}
                            />
                        </InputGroup>
                    </Form.Group>
                }

                <Form.Group className="my-4">
                    <Form.Label className='fs-14 fw-500'>Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Leave us a message..."
                        {...register("message", {
                            required: "Message is required",
                        })}
                        disabled={true}
                    />
                </Form.Group>
            </Form>
        </Modal.Body>
    </Modal>
};

export default View;