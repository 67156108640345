import { Container, Row, Col } from 'react-bootstrap';
import { CiLocationOn, CiCalendar } from "react-icons/ci";
import moment from 'moment';

// Images
import { badminton } from '../../assets/images'
import { User, Users, Men, Women } from '../../assets/images/svg'

// Utils
import { convertEventType, convertAgeGroup } from '../../utils/helper';

const OverviewSection = (props) => {
    return <Container fluid>
        {props?.tournament &&
            <Row className='my-4'>
                <Col xs={12} lg={7}>
                    <div className='d-flex flex-column align-items-start'>
                        {moment(new Date(props?.tournament?.registration_end_date)).diff(moment(new Date()), 'days') >= 0 ?
                            <span className='bg-success text-light px-2 rounded-pill fs-14 fw-500'>Open</span>
                            :
                            <span className='bg-danger text-light px-2 rounded-pill fs-14 fw-500'>Closed</span>
                        }
                        <div className='mt-2'>
                            <CiCalendar className='text-primary me-2' size={20} />
                            <span className='fs-14 fw-400 text-secondary'>Registration Date:&nbsp;</span>
                            <span className='fs-14 fw-400 text-secondary'>{moment(new Date(props?.tournament?.registration_start_date)).format("DD/MM/YYYY")} to {moment(new Date(props?.tournament?.registration_end_date)).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className='mt-2'>
                            <CiCalendar className='text-primary me-2' size={20} />
                            <span className='fs-14 fw-400 text-secondary'>Tournament Date:&nbsp;</span>
                            <span className='fs-14 fw-400 text-secondary'>{moment(new Date(props?.tournament?.tournament_start_date)).format("DD/MM/YYYY")} to {moment(new Date(props?.tournament?.tournament_end_date)).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className='mt-2'>
                            <CiLocationOn className='text-orange me-2' size={20} />
                            <span className='fs-14 fw-500 text-secondary'>{props?.tournament?.address}</span>
                        </div>

                        <div className='mt-4 d-flex flex-column align-items-start'>
                            <span className='fs-18 fw-600 mb-2'>Description</span>
                            <span className='fs-14 fw-400 text-secondary white-break-spaces'>{props?.tournament?.description}</span>
                        </div>

                        <div className='mt-4 d-flex flex-column align-items-start'>
                            <span className='fs-18 fw-600 mb-2'>Terms & Conditions</span>
                            <span className='fs-14 fw-400 text-secondary white-break-spaces'>{props?.tournament?.terms_and_condition}</span>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={5} className='mt-3 mt-lg-0'>
                    <div className='d-flex flex-column align-items-start'>
                        <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                            <span className='fw-600'>Type of Sports</span>
                            <div className='d-flex flex-column align-items-center text-light bg-orange rounded py-2 px-3 mt-3'>
                                <img className='img-fluid mt-2 mb-2' src={badminton} alt='badminton' />
                                <span>Badminton</span>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <span className='fw-600'>Categories</span>
                        {props?.tournament?.category_type === "INDIVIDUAL" &&
                            <div className='d-flex align-items-center mt-2'>
                                <User className='me-2' />
                                Individuals Event
                            </div>
                        }
                        {props?.tournament?.category_type === "TEAM" &&
                            <div className='d-flex justify-content-between align-items-center mt-2 w-100'>
                                <div className='d-flex align-items-center'>
                                    <Users className='me-2' />
                                    Team Event
                                </div>
                                <span className='fw-600'>RM{props?.tournament?.team_event_price}</span>
                            </div>
                        }
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <span className='fw-600'>Type of Individual Event ({props?.tournament?.event_details?.length || 0})</span>
                        {props?.tournament?.event_details?.length > 0 && props?.tournament?.event_details?.map((item, index) => {
                            return <div className='d-flex flex-wrap flex-column w-100' key={index}>
                                <div className='d-flex align-items-center text-orange mt-2'>
                                    {(item?.event_type === 'MEN_SINGLE' || item?.event_type === 'MEN_DOUBLE' || item?.event_type === 'MIX_DOUBLE' || item?.event_type === 'MEN_3_3' || item?.event_type === 'MIX_3_3' || item?.event_type === 'JUNIOR_SINGLE' || item?.event_type === 'JUNIOR_DOUBLE') && <Men color='#FA5000' className='me-2' />}
                                    {(item?.event_type === 'WOMEN_SINGLE' || item?.event_type === 'WOMEN_DOUBLE' || item?.event_type === 'MIX_DOUBLE' || item?.event_type === 'WOMEN_3_3' || item?.event_type === 'MIX_3_3' || item?.event_type === 'JUNIOR_SINGLE' || item?.event_type === 'JUNIOR_DOUBLE') && <Women color='#FA5000' className='me-2' />}
                                    {item?.event_type && convertEventType(item?.event_type)}
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-2 w-100 fs-14 fw-400 text=secondary'>
                                    <span>
                                        {convertAgeGroup(item?.age_group, item?.age_value)}
                                    </span>
                                    <span>RM{item?.price}</span>
                                </div>
                                {item?.number_of_males > 0 &&
                                    <div className='d-flex justify-content-between align-items-center mt-2 w-100 fs-14 fw-400 text=secondary'>
                                        <span>Male</span>
                                        <span>{item?.number_of_male}</span>
                                    </div>
                                }
                                {item?.number_of_females > 0 &&
                                    <div className='d-flex justify-content-between align-items-center mt-2 w-100 fs-14 fw-400 text=secondary'>
                                        <span>Female</span>
                                        <span>{item?.number_of_females}</span>
                                    </div>
                                }
                                {index !== props?.tournament?.event_details?.length - 1 && <hr className='w-100' />}
                            </div>
                        })}
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                            <span className='fs-18 fw-600'>Organizer</span>
                        </div>
                        <span className='text-orange fw-600'>{props?.tournament?.organizer?.name}</span>
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                            <span className='fs-18 fw-600'>Location</span>
                        </div>
                        <span className='text-secondary'>{props?.tournament?.address}</span>
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                            <span className='fs-18 fw-600'>Contacts</span>
                        </div>
                        <span className='text-secondary'>{props?.tournament?.contact}</span>
                        <span className='text-secondary fs-14'>{props?.tournament?.phone_number}</span>
                    </div>
                </Col>
            </Row>
        }
    </Container>
};

export default OverviewSection;