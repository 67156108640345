import axios from "axios"

/**
    * @function loginService
    * @param { email, password }
    * @description This function is used to signin the user
*/
export const loginService = async({ email, password }) =>{
    try {
        const result = await axios.post('/admin/login', {
            email,
            password
        })
        return result;
    } catch (error) {
        return error;
    }
}