import axios from "axios"

/**
    * @function allBankService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all bank
*/
export const allBankService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/bank/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addBankService
    * @param data
    * @description This function is used to add bank
*/
export const addBankService = async(data) =>{
    try {
        const result = await axios.post(`/admin/bank/store`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function bankStatusService
    * @param id
    * @description This function is used to change bank status
*/
export const bankStatusService = async(id) =>{
    try {
        const result = await axios.post(`/admin/bank/status/${id}`, {})
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateBankService
    * @param { id, data }
    * @description This function is used to update bank
*/
export const updateBankService = async({ id, data }) =>{
    try {
        const result = await axios.post(`/admin/bank/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getSingleBankService
    * @param { id }
    * @description This function is used to get single bank details
*/
export const getSingleBankService = async({ id }) =>{
    try {
        const result = await axios.get(`/admin/bank/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteBankService
    * @param id
    * @description This function is used to delete bank
*/
export const deleteBankService = async(id) =>{
    try {
        const result = await axios.delete(`/admin/bank/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}