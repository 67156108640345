import { useState, useEffect } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import { Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaTrashAlt, FaRegEdit } from "react-icons/fa";
import { PiUserCircleLight } from "react-icons/pi";
import { AiOutlineTransaction } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import moment from 'moment'

// Custom Component
import Add from './Add'
import Edit from './Edit'
import { PrimaryButton } from '../../components/Button';
import SearchInput from '../../components/SearchInput/SearchInput';
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import ConfirmAlert from '../../components/Alert/ConfirmAlert';

// API Service
import { allOrganizerService, organizerStatusService, deleteOrganizerService } from '../../services/organizer.service';

const Organizer = () => {
    const navigate = useNavigate();

    const [organizerList, setOrganizerList] = useState([])
    const [selectedData, setSelectedData] = useState(null);
    const [searchTag, setSearchTag] = useState("")
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [csvData, setCSVData] = useState([]);
    const countPerPage = 10;

    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)

    const csvHeaders = [
        { label: 'Username', key: 'username' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phone' },
        { label: 'Country', key: 'country' },
        { label: 'Status', key: 'status' },
        { label: 'Created Date', key: 'created_at' }
    ];

    /**
         * @function fetchOrganizerList
         * @params page
         * @description fetch the list of organizer
         */
    const fetchOrganizerList = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allOrganizerService({
                paginate: 1,
                page: page + 1,
                perPage: 10,
                keyword: searchTag
            });
            if (result?.data?.status) {
                setOrganizerList(result?.data?.data?.organizers)
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
         * @function fetchAllOrganizerList
         * @params 
         * @description fetch the list of all organizer
         */
    const fetchAllOrganizerList = async () => {
        try {
            const result = await allOrganizerService({
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                setCSVData(result?.data?.data?.organizers?.map(item => { 
                    return {
                        username: item?.name,
                        email: item?.email,
                        phone: item?.phone,
                        country: item?.country?.name,
                        status: item?.status,
                        created_at: moment(item.created_at).format("DD-MM-YYYY")
                    }
                }))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchOrganizerList(currentPage)
    }, [searchTag])

    useEffect(() => {
        fetchAllOrganizerList()
    }, [])

    /**
         * @function handleAddModal
         * @params
         * @description set the visibility of add modal
         */
    const handleAddModal = () => {
        setAddModal(!addModal)
    }

    /**
         * @function handleEditModal
         * @params row
         * @description set the visibility of edit modal
         */
    const handleEditModal = (row) => {
        setEditModal(!editModal)
        setSelectedData(row)
    }

    /**
         * @function statusUpdate
         * @params rowRecord
         * @description used to change status
         */
    const statusUpdate = async (rowRecord) => { 
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await organizerStatusService(rowRecord?.id);
            if (result?.data?.status) {
                SuccessAlert('Status updated successfully')
                fetchOrganizerList(currentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
         * @function deleteOrganizer
         * @params id
         * @description delete the organizer
         */
    const deleteOrganizer = async (id) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await deleteOrganizerService(id);
            if (result?.data?.status) {
                SuccessAlert('Organizer deleted successfully')
                fetchOrganizerList(currentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => currentPage > 0 ? (currentPage * 10) + index + 1 : index + 1
        },
        {
            name: 'Profile Image',
            selector: (row) => row.profile_image ? <img className='rounded-circle' src={row.profile_image} alt={row.username} width={32} height={32} /> : <PiUserCircleLight className="text-secondary rounded-circle" size={32} />,
            sortable: true
        },
        {
            name: 'Name',
            selector: (row) => row.name ? row.name : '--',
            sortable: true
        },
        {
            name: 'Phone',
            selector: (row) => row.phone ? row.phone : '--',
            sortable: true
        },
        {
            name: 'Email',
            selector: (row) => row.email ? row.email : '--',
            sortable: true
        },
        {
            name: 'Address',
            selector: (row) => row.address ? row.address : '--',
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => (
                <Form.Check
                    type="switch"
                    id="success-switch"
                    checked={row.isActive}
                    onChange={() => statusUpdate(row)}
                    defaultChecked={row.status}
                />
            )
        },
        {
            name: 'Created Date',
            selector: (row) => moment(row.created_at).format("DD-MM-YYYY")
        },
        {
            name: 'Actions',
            allowOverflow: true,
            selector: (row) => <>
                <FaTrashAlt role='button' className='text-danger me-2' size={20} onClick={() => ConfirmAlert(() => deleteOrganizer(row?.id))} />
                <FaRegEdit role='button' className='text-orange me-2' size={20} onClick={() => handleEditModal(row)} />
                <AiOutlineTransaction role='button' className='text-orange me-2' size={20} onClick={() => navigate(`/transaction/${row?.id}`)} />
                <FiUsers role='button' className='text-orange' size={20} onClick={() => navigate(`/sub-organizer/${row?.id}`)} />
            </>
        }
    ]

    /**
         * @function handlePagination
         * @params page
         * @description used to handle Pagination
         */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchOrganizerList(page.selected)
    }

    /**
         * @function searchOrganizer
         * @params searchTerm
         * @description used to set the value of search tag
         */
    const searchOrganizer = (searchTerm) => {
        setCurrentPage(0)
        setSearchTag(searchTerm)
    }

    // ** Custom Pagination
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <CCard className="mb-4">
                        <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center flex-wrap'>
                            <h5>Organizer Management</h5>
                            <div className='d-flex flex-wrap align-items-center'>
                                <div className='me-3'>
                                    {csvData?.length > 0 &&
                                        <CSVLink filename={`organizer.csv`} data={csvData} headers={csvHeaders}>
                                            <PrimaryButton>Export</PrimaryButton>
                                        </CSVLink>
                                    }
                                </div>
                                <div className='me-3'>
                                    <PrimaryButton onClick={handleAddModal}>Add Organizer</PrimaryButton>
                                </div>
                                <Form id='search-form' noValidate>
                                    <SearchInput placeholder='Search Organizer' search={searchOrganizer} />
                                </Form>
                            </div>
                        </CCardHeader>
                    </CCard>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            data={organizerList}
                            pagination
                            paginationServer
                            paginationTotalRows={totalEntry}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            paginationComponent={CustomPagination}
                            paginationDefaultPage={currentPage + 1}
                        />
                    </div>
                </CCol>
            </CRow>
        </CContainer>

        {/* Add Modal */}
        <Add show={addModal} handleClose={handleAddModal} pageCount={currentPage} fetchOrganizerList={fetchOrganizerList} />

        {/* Edit Modal */}
        <Edit show={editModal} handleClose={handleEditModal} selectedData={selectedData} pageCount={currentPage} fetchOrganizerList={fetchOrganizerList} />
    </div>
};

export default Organizer
