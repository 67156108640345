import { CRow, CCol, CCard, CCardHeader, CCardBody } from '@coreui/react'

const Dashboard = () => {
    return <CRow>
        <CCol xs>
            <CCard className="mb-4">
                <CCardHeader className='bg-transparent border-0 text-secondary h5'>Kick start your admin panel 🚀</CCardHeader>
                <CCardBody>
                    <h2>Welcome to Dashboard</h2>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
}

export default Dashboard
