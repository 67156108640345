import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CContainer, CHeader, CHeaderBrand, CHeaderDivider, CHeaderNav, CHeaderToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'

// Custom Component
import { AppBreadcrumb, AppHeaderDropdown } from './index'

// Images
import { logo } from '../../assets/images'

const AppHeader = () => {
    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)
    const loading = useSelector((state) => state.loading)

    return <CHeader position="sticky" className="mb-4">
        <CContainer fluid>
            <CHeaderToggler
                className="ps-1"
                onClick={() => dispatch({
                    type: 'set',
                    sidebarShow: !sidebarShow,
                    loading
                })}
            >
                <CIcon icon={cilMenu} size="lg" />
            </CHeaderToggler>
            <CHeaderBrand className="mx-auto d-md-none" to="/">
                <Link to='/'>
                    <img className="img-fluid" src={logo} alt="AFA" />
                </Link>
            </CHeaderBrand>
            <CHeaderNav className="ms-3">
                <AppHeaderDropdown />
            </CHeaderNav>
        </CContainer>
        <CHeaderDivider />
        <CContainer fluid>
            <AppBreadcrumb />
        </CContainer>
    </CHeader>
}

export default AppHeader
