import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from "axios"
import { Provider } from 'react-redux'
import 'react-app-polyfill/stable'
import 'core-js'

// Custom Component
import App from './App'
import reportWebVitals from './reportWebVitals'

// Redux-Functions
import store from './store'

// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

axios.interceptors.request.use((request) => {
    if (request.url) {
        request.url = process.env.REACT_APP_APP_API_BACKEND_SERVER + request.url;
    }
    if (localStorage.getItem("token")) {
      request.headers['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    }
    return request;
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
