import { CAvatar, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import { cilSettings } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useNavigate } from "react-router-dom";

// Images
import { avatar } from '../../assets/images'

// Utility Service
import { removeUserSession } from '../../utils/AuthService';

const AppHeaderDropdown = () => {
	const navigate = useNavigate();
	
	/**
         * @function handleLogout
         * @params
         * @description used for logout
         */
    const handleLogout = () => {
		removeUserSession();
		navigate('/login')
    };
	
	return <CDropdown variant="nav-item">
		<CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
			<CAvatar src={avatar} size="md" />
		</CDropdownToggle>
		<CDropdownMenu className='py-0' placement="bottom-end">
			<CDropdownItem role='button' className='py-2' onClick={handleLogout}>
				<CIcon icon={cilSettings} className="me-2" />
				Logout
			</CDropdownItem>
		</CDropdownMenu>
	</CDropdown>
}

export default AppHeaderDropdown;
