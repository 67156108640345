import { useState, useEffect } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import { Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector} from 'react-redux';
import { CSVLink } from "react-csv";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { PiUserCircleLight } from "react-icons/pi";
import moment from 'moment'

// Custom Component
import { PrimaryButton } from '../../components/Button';
import SearchInput from '../../components/SearchInput/SearchInput';
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allUserService, userStatusService } from '../../services/user.service';

const User = () => {
    const [userList, setUserList] = useState([])
    const [searchTag, setSearchTag] = useState("")
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const [csvData, setCSVData] = useState([]);
    const countPerPage = 10;

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)

    const csvHeaders = [
        { label: 'Username', key: 'username' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phone' },
        { label: 'Country', key: 'country' },
        { label: 'Status', key: 'status' },
        { label: 'Created Date', key: 'created_at' },
    ];

    /**
         * @function fetchUserList
         * @params page
         * @description fetch the list of user
         */
    const fetchUserList = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allUserService({
                paginate: 1,
                page: page + 1,
                perPage: countPerPage,
                keyword: searchTag
            });
            if (result?.data?.status) {
                setUserList(result?.data?.data?.customers)
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
         * @function fetchAllUserList
         * @params
         * @description fetch the list of all user
         */
    const fetchAllUserList = async () => {
        try {
            const result = await allUserService({
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                setCSVData(result?.data?.data?.customers?.map(item => { 
                    return {
                        username: item?.username,
                        email: item?.email,
                        phone: item?.phone,
                        country: item?.country?.name,
                        status: item?.status,
                        created_at: moment(item.created_at).format("DD-MM-YYYY")
                    }
                }))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchUserList(currentPage)
    }, [searchTag])

    useEffect(() => {
        fetchAllUserList()
    }, [])

    /**
         * @function statusUpdate
         * @params rowRecord
         * @description used to change status
         */
    const statusUpdate = async (rowRecord) => { 
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await userStatusService({
                id: rowRecord?.id
            });
            if (result?.data?.status) {
                SuccessAlert('Status updated successfully')
                fetchUserList(currentPage)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => currentPage > 0 ? (currentPage * 10) + index + 1 : index + 1
        },
        {
            name: 'Profile Image',
            selector: (row) => row.profile_image ? <img className='rounded-circle' src={row.profile_image} alt={row.username} width={32} height={32} /> : <PiUserCircleLight className="text-secondary rounded-circle" size={32} />,
            sortable: true
        },
        {
            name: 'Username',
            selector: (row) => row.username ? row.username : '--',
            sortable: true
        },
        {
            name: 'Email',
            selector: (row) => row.email ? row.email : '--',
            sortable: true
        },
        {
            name: 'Phone',
            selector: (row) => row.phone ? row.phone : '--',
            sortable: true
        },
        {
            name: 'Country',
            selector: (row) => row?.country?.name ? row?.country?.name : '--',
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => (
                <Form.Check
                    type="switch"
                    id="success-switch"
                    checked={row.status}
                    onChange={() => statusUpdate(row)}
                    defaultChecked={row.status}
                />
            )
        },
        {
            name: 'Created Date',
            selector: (row) => moment(row.created_at).format("DD-MM-YYYY")
        }
    ]

    /**
         * @function handlePagination
         * @params page
         * @description used to handle Pagination
         */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchUserList(page.selected)
    }

    /**
         * @function searchUser
         * @params searchTerm
         * @description used to set the value of search tag
         */
    const searchUser = (searchTerm) => {
        setCurrentPage(0)
        setSearchTag(searchTerm)
    }

    // ** Custom Pagination
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <CCard className="mb-4">
                        <CCardHeader className='d-flex justify-content-between align-items-center bg-transparent border-0 text-dark h5'>
                            User Management
                            <div className='d-flex align-items-center'>
                                <div className='me-2'>
                                    {csvData?.length > 0 &&
                                        <CSVLink filename={`user.csv`} data={csvData} headers={csvHeaders}>
                                            <PrimaryButton>Export</PrimaryButton>
                                        </CSVLink>
                                    }
                                </div>
                                <Form id='search-form' noValidate>
                                    <SearchInput placeholder='Search User' search={searchUser} />
                                </Form>
                            </div>
                        </CCardHeader>
                    </CCard>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        {userList?.length > 0 &&
                            <DataTable
                                noHeader
                                columns={columns}
                                className='react-dataTable'
                                data={userList}
                                pagination
                                paginationServer
                                paginationTotalRows={totalEntry}
                                paginationPerPage={countPerPage}
                                paginationComponentOptions={{ noRowsPerPage: true }}
                                paginationComponent={CustomPagination}
                                paginationDefaultPage={currentPage + 1}
                            />
                        }
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    </div>
};

export default User
