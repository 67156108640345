import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react'

// Custom Component
import { AppSidebarNav } from './AppSidebarNav'

// Sidebar Nav Config
import navigation from '../../_nav'

// Images
import { logo } from '../../assets/images'

const AppSidebar = () => {
    const dispatch = useDispatch()
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)
    const loading = useSelector((state) => state.loading)

    return <CSidebar
        position="fixed"
        unfoldable={unfoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => {
            dispatch({
                type: 'set',
                sidebarShow: visible,
                loading
            })
        }}
    >
        <CSidebarBrand to="/">
            <Link to='/'>
                <img className="img-fluid" src={logo} alt="AFA" />
            </Link>
        </CSidebarBrand>
        <CSidebarNav>
            <SimpleBar>
                <AppSidebarNav items={navigation} />
            </SimpleBar>
        </CSidebarNav>
    </CSidebar>
}

export default React.memo(AppSidebar)
