import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'

// routes config
import routes from '../../routes'

const AppContent = () => {
    return (
        <CContainer fluid className='px-2'>
            <Suspense fallback={<CSpinner color="primary" />}>
                <Routes>
                    {routes.map((route, index) => {
                        return (
                            route.element && <Route key={index} path={route.path} exact={route.exact} name={route.name} element={<route.element />} />
                        )
                    })}
                    <Route path="/" element={<Navigate to="dashboard" replace />} />
                    <Route path="*" element={<Navigate to="dashboard" replace />} />
                </Routes>
            </Suspense>
        </CContainer>
    )
}

export default React.memo(AppContent)
