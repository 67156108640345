import { useState, useEffect } from 'react'
import { CContainer, CCol, CRow, CCard, CCardHeader } from '@coreui/react'
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector} from 'react-redux';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import moment from 'moment'

// Custom Component
import SearchInput from '../../components/SearchInput/SearchInput';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allTournamentService } from '../../services/tournament.service';

const Tournament = () => {
    const navigate = useNavigate()
    const [tournamentList, setTournamentList] = useState([])
    const [searchTag, setSearchTag] = useState("")
    const [currentPage, setCurrentPage] = useState(0)
    const [totalEntry, setTotalEntry] = useState(1)
    const countPerPage = 10;

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)

    /**
         * @function fetchListedTournament
         * @params page
         * @description fetch the list of tournament
         */
    const fetchListedTournament = async (page) => {
        try {
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const result = await allTournamentService({
                paginate: 1,
                page: page + 1,
                perPage: countPerPage,
                keyword: searchTag
            });
            if (result?.data?.status) {
                setTournamentList(result?.data?.data?.tournament_lists)
                setTotalEntry(result?.data?.data?.pagination?.total_pages)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    useEffect(() => {
        fetchListedTournament(currentPage)
    }, [searchTag])

    const columns = [
        {
            name: 'ID',
            selector: (row, index) => currentPage > 0 ? (currentPage * 10) + index + 1 : index + 1
        },
        {
            name: 'Name',
            selector: (row) => row?.name ? row?.name : '--',
            sortable: true
        },
        {
            name: 'Address',
            selector: (row) => row?.address ? row?.address : '--',
            sortable: true
        },
        {
            name: 'Allow Professional Players',
            selector: (row) => row?.allow_proffesional_players ? 'Yes' : 'No',
            sortable: true
        },
        {
            name: 'Banner Image',
            selector: (row) => row?.banner_image ? <img className='img-fluid' src={row?.banner_image} alt={row?.name} width={80} /> : '--',
            sortable: true
        },
        {
            name: 'Category',
            selector: (row) => row?.category_type ? row?.category_type === 'INDIVIDUAL' ? 'Individuals' : 'Team' : '--',
            sortable: true
        },
        {
            name: 'Published',
            selector: (row) => (
                <Form.Check
                    type="switch"
                    id="success-switch"
                    checked={row?.is_published}
                    defaultChecked={row?.is_published}
                    disabled
                />
            )
        },
        {
            name: 'Created Date',
            selector: (row) => moment(row.created_at).format("DD-MM-YYYY")
        },
        {
            name: 'Actions',
            allowOverflow: true,
            selector: (row) => <>
                <FaEye role='button' className='text-orange' size={20} onClick={() => navigate(`/tournament/single/${row?.id}`)} />
            </>
        }
    ]

    /**
         * @function handlePagination
         * @params page
         * @description used to handle Pagination
         */
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
        fetchListedTournament(page.selected)
    }

    /**
         * @function searchTournament
         * @params searchTerm
         * @description used to set the value of search tag
         */
    const searchTournament = (searchTerm) => {
        setCurrentPage(0)
        setSearchTag(searchTerm)
    }

    // ** Custom Pagination
    const CustomPagination = () => (
        <ReactPaginate
            nextLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <span className='fs-14 fw-600 me-2'>Next</span>
                <AiOutlineArrowRight className='text-secondary' size={20} />
            </div>}
            breakLabel='...'
            previousLabel={<div className='d-inline-flex align-items-center bg-light p-2 border rounded'>
                <AiOutlineArrowLeft className='text-secondary me-2' size={20} />
                <span className='fs-14 fw-600'>Previous</span>
            </div>}
            pageRangeDisplayed={3}
            forcePage={currentPage}
            marginPagesDisplayed={3}
            activeClassName='active bg-light border rounded px-3 d-flex align-items-center text-dark'
            pageClassName='page-item me-3 d-flex align-items-center text-secondary'
            breakClassName='page-item me-3 d-flex align-items-center'
            nextClassName='page-item next-item flex-grow-1 text-end'
            previousClassName='page-item prev-item flex-grow-1 me-3'
            disabledLinkClassName='btn disabled p-0 border-0'
            pageCount={totalEntry || 1}
            onPageChange={page => handlePagination(page)}
            containerClassName='pagination react-paginate separated-pagination pagination-sm pe-1 mt-3'
        />
    )

    return <div className="bg-light min-vh-100 d-flex flex-row">
        <CContainer fluid>
            <CRow>
                <CCol xs={12} className='px-0'>
                    <CCard className="mb-4">
                        <CCardHeader className='bg-transparent border-0 text-dark d-flex justify-content-between align-items-center'>
                            <h5>Tournament Management</h5>
                            <Form id='search-form' noValidate>
                                <SearchInput placeholder='Search Tournament' search={searchTournament} />
                            </Form>
                        </CCardHeader>
                    </CCard>
                    <div className='react-dataTable react-dataTable-selectable-rows'>
                        <DataTable
                            noHeader
                            columns={columns}
                            className='react-dataTable'
                            data={tournamentList}
                            pagination
                            paginationServer
                            paginationTotalRows={totalEntry}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            paginationComponent={CustomPagination}
                            paginationDefaultPage={currentPage + 1}
                        />
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    </div>
};

export default Tournament;
