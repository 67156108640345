import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import { CNavItem } from '@coreui/react'
import { TbTournament } from "react-icons/tb";
import { BsFlag, BsBank } from "react-icons/bs";
import { GiVerticalBanner } from "react-icons/gi";
import { BiMoneyWithdraw, BiMessageRounded } from "react-icons/bi";
import { VscOrganization, VscSymbolString } from "react-icons/vsc";

const _nav = [
    {
        component: CNavItem,
        name: 'User',
        to: '/user/management',
        icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    },
    {
        component: CNavItem,
        name: 'Country',
        to: '/country/management',
        icon: <BsFlag className="nav-icon" size={20} />,
    },
    {
        component: CNavItem,
        name: 'Banner',
        to: '/banner/management',
        icon: <GiVerticalBanner className="nav-icon" size={20} />,
    },
    {
        component: CNavItem,
        name: 'Organizer',
        to: '/organizer/management',
        icon: <VscOrganization className="nav-icon" size={20} />,
    },
    {
        component: CNavItem,
        name: 'Tournament',
        to: '/tournament/management',
        icon: <TbTournament className="nav-icon" size={20} />,
    },
    {
        component: CNavItem,
        name: 'Bank',
        to: '/bank/management',
        icon: <BsBank className="nav-icon" size={20} />,
    },
    {
        component: CNavItem,
        name: 'Withdraw',
        to: '/withdraw/management',
        icon: <BiMoneyWithdraw className="nav-icon" size={20} />,
    },
    {
        component: CNavItem,
        name: 'String',
        to: '/string/management',
        icon: <VscSymbolString className="nav-icon" size={20} />,
    },
    {
        component: CNavItem,
        name: 'Contact Us',
        to: '/message/management',
        icon: <BiMessageRounded className="nav-icon" size={20} />,
    }
]

export default _nav
