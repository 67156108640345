import axios from "axios"

/**
    * @function allTournamentService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all tournament
*/
export const allTournamentService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/admin/tournament/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getSingleTournamentService
    * @param { id }
    * @description This function is used to get single tournament details
*/
export const getSingleTournamentService = async({ id }) =>{
    try {
        const result = await axios.get(`/admin/tournament/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allPlayerService
    * @param { id, gender, event_type, paginate, page, perPage, keyword }
    * @description This function is used to get all player of a tournament for admin
*/
export const allPlayerService = async({ id, gender, event_type, paginate, page, perPage, keyword }) =>{
    try {
        let result
        if (gender && event_type) {
            result = await axios.get(`/admin/tournament/single/players/${id}?gender=${gender}&event_type=${event_type}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else if (gender) {
            result = await axios.get(`/admin/tournament/single/players/${id}?gender=${gender}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else if (event_type) {
            result = await axios.get(`/admin/tournament/single/players/${id}?event_type=${event_type}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else {
            result = await axios.get(`/admin/tournament/single/players/${id}?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        }
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allEarningService
    * @param { id, gender, event_type, paginate, page, perPage, keyword }
    * @description This function is used to get all earning of a tournament for admin
*/
export const allEarningService = async({ id, gender, event_type, paginate, page, perPage, keyword }) =>{
    try {
        let result
        if (gender && event_type) {
            result = await axios.get(`/admin/tournament/single/earnings/${id}?gender=${gender}&event_type=${event_type}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else if (gender) {
            result = await axios.get(`/admin/tournament/single/earnings/${id}?gender=${gender}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else if (event_type) {
            result = await axios.get(`/admin/tournament/single/earnings/${id}?event_type=${event_type}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else {
            result = await axios.get(`/admin/tournament/single/earnings/${id}?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        }
        return result;
    } catch (error) {
        return error;
    }
}

