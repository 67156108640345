import { useState } from 'react'
import { Modal, Form, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector} from 'react-redux';
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";

// Custom Component
import ErrorText from '../../components/TextField/ErrorText'
import SuccessAlert from '../../components/Alert/SuccessAlert';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import { PrimaryButton, PrimaryOutlineButton } from '../../components/Button';

// API Service
import { addBankService } from '../../services/bank.service';

const Add = (props) => {
    const [bankImage, setBankImage] = useState(null);

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)
    const loading = useSelector((state) => state.loading)

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
         * @function onSubmit
         * @params formData
         * @description used to add bank
         */
    const onSubmit = async (formData) => {
        try {
            if (!bankImage) {
                ErrorAlert('Please upload bank image')
                return;
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: true
            })
            const data = new FormData()
            data.append('name', formData?.name)
            data.append('image', bankImage)
            const result = await addBankService(data);
            if (result?.data?.status) {
                SuccessAlert('Bank added successfully')
                props?.fetchListedBank(props?.pageCount)
                handleModalClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        } catch (error) {
            ErrorAlert(error)
            dispatch({
                type: 'set',
                sidebarShow,
                loading: false
            })
        }
    }

    /**
         * @function handleModalClose
         * @params
         * @description close add bank modal
         */
    const handleModalClose = () => {
        setBankImage()
        reset()
        props.handleClose()
    }

    return <Modal show={props.show} onHide={handleModalClose} centered>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-18 fw-600 border-0' closeButton>
                Add Bank
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center'>
                <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Name*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            {...register("name", {
                                required: "Name is required"
                            })}
                        />
                        {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                    </Form.Group>

                    <input className='d-none' type='file' id="bankPhotoUpload" accept="image/*" onChange={(e) => {
                        if (e.target.files.length > 0) {
                            setBankImage(e.target.files[0])
                        }
                    }} onClick={(e) => e.target.value = null} />
                    <label htmlFor="bankPhotoUpload">
                        {bankImage instanceof File
                            ?
                            <div role='button' className='d-inline-flex flex-column mb-4'>
                                <span className='mb-2'>Flag</span>
                                <img className='img-fluid' src={URL.createObjectURL(bankImage)} alt={'bankImage'} />
                            </div>
                            :
                            bankImage
                                ?
                                <div role='button' className='d-inline-flex flex-column mb-4'>
                                    <span className='mb-2'>Flag</span>
                                    <img className='img-fluid' src={bankImage} alt={'bankImage'} />
                                </div>
                                :
                                <div role='button' className='d-inline-flex flex-column justify-content-between align-items-center mb-4 border px-2 py-3'>
                                    <FaPlus />
                                    <span className='fs-14 fw-400 mt-2'>Add image</span>
                                </div>
                        }
                    </label>

                    <div className='d-flex justify-content-end align-items-center mb-4'>
                        <div className='me-3'>
                            <PrimaryOutlineButton type="button" className='fs-14 fw-500' onClick={handleModalClose}>Cancel</PrimaryOutlineButton>
                        </div>
                        <div>
                            <PrimaryButton className='fs-14 fw-500' type="submit">Add</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default Add;